import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { domain, request } from "@/utils/request";
import VueRouter from "vue-router"

import TIM from 'tim-wx-sdk';


//创建腾讯IM账号
let tim = null;

tim = TIM.create({
	SDKAppID: '1600027713'
});

Vue.prototype.tim = tim;

Vue.use(ElementUI);


Vue.prototype.url_img = domain
Vue.prototype.$http = request

Vue.use(VueRouter)


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
