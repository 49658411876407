import Vue from 'vue'
import VueRouter from 'vue-router'

// import index from './components/index.vue'
// import hello from './components/hello.vue'

Vue.use(VueRouter)


export default new VueRouter({
	routes: [
		{
			path: '/',
			meta: { title: '首页' },
			component: () => import("../components/HelloWorld")
		},
		{
			path: '/login',
			meta: { title: '登录' },
			component: () => import("../components/loginPage")
		},
		{
			path: '/play',
			meta: { title: '播放页' },
			component: () => import("../components/playPage")
		}
		// ,
		// {
		// 	path: '/coursePay',
		// 	meta: { title: '课程详情' },
		// 	component: () => import("../components/coursePay")
		// },
		// {
		// 	path: '/user',
		// 	meta: { title: '用户中心' },
		// 	component: () => import("../components/userInfo")
		// }
	]
})