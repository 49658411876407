// const domain = "http://l2632renfuyuan.kk/"
const domain = "https://adminshop.renfuyuan.top"


import axios from 'axios'
function request(url, data, method) {
	var token = ''
	if (localStorage.getItem('token')) {
		token = localStorage.getItem('token')
	}
	return new Promise((resolve) => {
		axios({
			url: domain + url,
			headers: {
				'content-Type': 'application/json',
				"token": token
			},
			data,
			method: method || 'POST'
		}).then(res => {
			return resolve(res.data)
		})

	})
}
export {
	request,
	domain
};
